/* DataTableDemo.css */

.datatable-rowgroup-demo .p-rowgroup-footer td {
	font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
	font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
	vertical-align: middle;
	margin-right: 0.25rem;
}

.p-rowgroup-header {
	width: 100% !important;
	border: 2px solid #99bfe3;
}

.p-rowgroup-footer {
	width: 100% !important;
	border: 2px solid #99bfe3;
}
